import { COLORS } from '@global/styles/colors';
import {
  DESKTOP_1200,
  DESKTOP_1280,
  TABLET_768,
  TABLET_800,
  TABLET_992,
  ZINDEX,
} from '@global/styles/sizes';
import styled from 'styled-components';

export const TitleContainer = styled.h1`
  margin-bottom: 20px;
  border-bottom: 1px solid #d5d5d5;
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 36px;
  line-height: 40px;
  color: #000000;

  p {
    margin: 0;
  }
  @media (max-width: ${TABLET_768}px) {
    font-size: 34px;
    line-height: 38px;
  }
`;

export const VideoTitleContainer = styled.h2`
  margin-bottom: 24px;
  padding-right: 50px;
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 36px;
  line-height: 40px;
  color: #000000;
  position: relative;

  p {
    margin: 0;
  }
  @media (max-width: ${TABLET_768}px) {
    font-size: 34px;
    line-height: 38px;
  }
`;

export const SubtitleContainer = styled.div`
  margin: 26px 0;
  font-family: 'Poppins';
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
  color: ${COLORS.resolutionBlue};

  @media (max-width: ${TABLET_768}px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const Schedule = styled.p`
  margin: 0;
  font-family: 'Poppins';
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  color: #525252;
`;

export const SeparateLine = styled.div`
  margin: 0 8px;
  font-size: 16px;
  line-height: 23px;
  color: #525252;

  @media (max-width: ${DESKTOP_1280}px) {
    display: none;
  }
`;

export const WebinarInfo = styled.div`
  margin: 14px 0 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${TABLET_768}px) {
    align-items: flex-start;
  }
`;

export const Duration = styled.p`
  margin: 0;
  font-family: 'Poppins';
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  color: #525252;
`;

export const WebinarInfoWrapper = styled.div`
  display: flex;

  @media (max-width: ${DESKTOP_1280}px) {
    display: none;
  }
`;
export const WebinarButtonsWrapper = styled.div`
  display: flex;
`;

export const ButtonContainer = styled.div`
  display: flex;

  @media (max-width: ${TABLET_768}px) {
    display: none;
  }
`;

export const ButtonMobileContainer = styled.div`
  display: none;

  @media (max-width: ${TABLET_768}px) {
    margin-top: 9px;
    display: flex;
  }
`;

export const WebinarInfoMobileWrapper = styled.div`
  display: none;

  @media (max-width: ${DESKTOP_1280}px) {
    display: flex;
    flex-direction: column;
    gap: 11px;
  }
`;

export const MobileContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Share = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;

  svg {
    width: 36px;
    height: 36px;
  }

  &:hover {
    svg {
      circle {
        fill: #0e8cb9;
      }
    }
  }
`;

export const ShareTooltip = styled.div<{ renderTooltip: boolean }>`
  width: max-content;
  padding: 10px;
  bottom: 90%;
  right: -100%;
  visibility: ${({ renderTooltip }) => (renderTooltip ? 'visible' : 'hidden')};
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  color: #0792b1;
  text-decoration: none;
  background-color: #e5f4f7;
`;

export const Tag = styled.a`
  min-width: 83px;
  height: 29px;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  color: #0792b1;
  text-decoration: none;
  background-color: #e5f4f7;
  cursor: pointer;

  &:hover {
    background-color: ${COLORS.bondiBlue};
    color: ${COLORS.white};
  }
`;

export const TagsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 11px;
  flex-wrap: wrap;
`;

export const WebinarInfoIcon = styled.img`
  width: 22px;
  height: 22px;
  margin-right: 11px;
`;

export const Button = styled.a`
  width: 230px;
  height: 48px;
  margin-left: 16px;
  display: flex;
  justify-content: center;
  text-decoration: none;
  align-items: center;
  border-radius: 8px;
  font-size: 22px;
  line-height: 33px;
  font-family: 'Poppins';
  font-weight: bold;
  background-color: #0e8cb9;
  color: ${COLORS.white};
  border: 1px solid #0e8cb9;

  p,
  strong {
    font-family: 'Poppins';
  }

  &:hover {
    background-color: ${COLORS.white};
    color: #0e8cb9;
  }

  @media (max-width: ${TABLET_768}px) {
    margin-left: 0;
  }
`;

export const VideoButton = styled.div`
  width: 230px;
  height: 48px;
  margin-left: 16px;
  display: flex;
  justify-content: center;
  text-decoration: none;
  align-items: center;
  border-radius: 8px;
  font-size: 22px;
  line-height: 33px;
  font-family: 'Poppins';
  font-weight: bold;
  background-color: #0e8cb9;
  color: ${COLORS.white};
  border: 1px solid #0e8cb9;

  p,
  strong {
    font-family: 'Poppins';
  }

  &:hover {
    background-color: ${COLORS.white};
    color: #0e8cb9;
  }

  @media (max-width: ${TABLET_768}px) {
    margin-left: 0;
  }
`;

export const ModalContainer = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  top: 75px;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${ZINDEX.modal};

  @media (max-width: ${DESKTOP_1200}px) {
    padding: 0 20px;
  }
`;

export const VideoContainer = styled.div`
  width: 90%;
  max-width: 1016px;
  height: 70%;
  padding: 26px 19px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-self: center;
  position: relative;
  background-color: ${COLORS.white};
  z-index: 800;

  iframe {
    border-radius: 10px;
  }

  @media (max-width: ${DESKTOP_1200}px) {
    margin: 0 20px;
  }

  @media (max-width: ${TABLET_800}px) {
    height: 60%;
  }
`;

export const CloseIcon = styled.img`
  width: 40px;
  height: 40px;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
`;
