import React, { useEffect, useRef, useState } from 'react';
import { GlobalWrapper } from '@global/styles/grid';
import { Markdown } from '@components/StrapiComponents';
import ReactPlayer from 'react-player';
import CloseIconVideo from './assets/close-icon.svg';
import { WebinarTitleProps } from './webinarTitle.types';
import DurationIcon from './assets/duration.svg';
import ScheduleIcon from './assets/schedule.svg';
import {
  Duration,
  WebinarInfo,
  SeparateLine,
  SubtitleContainer,
  TitleContainer,
  Schedule,
  Share,
  WebinarInfoWrapper,
  WebinarInfoIcon,
  TagsContainer,
  Tag,
  Button,
  WebinarInfoMobileWrapper,
  MobileContainer,
  WebinarButtonsWrapper,
  ButtonContainer,
  ButtonMobileContainer,
  ShareTooltip,
  VideoButton,
  CloseIcon,
  ModalContainer,
  VideoContainer,
  VideoTitleContainer,
} from './webinarTitle.styles';
import ShareIcon from './assets/share';

const WebinarTitle = ({
  title,
  subtitle,
  roles,
  topics,
  careers,
  duration,
  schedule,
  webinarLink,
}: WebinarTitleProps) => {
  const [renderTooltip, setRenderTooltip] = useState(false);
  const [renderVideoModal, setRenderVideoModal] = useState(false);
  const modalRef = useRef();

  const today = new Date();
  const todayUtc = Date.UTC(
    today.getUTCFullYear(),
    today.getUTCMonth(),
    today.getUTCDate(),
    today.getUTCHours(),
    today.getUTCMinutes(),
    today.getUTCSeconds(),
  );
  const webinarSchedule = new Date(schedule);
  const webinarScheduleUtc = Date.UTC(
    webinarSchedule.getUTCFullYear(),
    webinarSchedule.getUTCMonth(),
    webinarSchedule.getUTCDate(),
    webinarSchedule.getUTCHours(),
    webinarSchedule.getUTCMinutes(),
    webinarSchedule.getUTCSeconds(),
  );

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(window.location.href);
    setRenderTooltip(true);
  };

  useEffect(() => {
    setTimeout(() => {
      setRenderTooltip(false);
    }, 3000);
  }, [renderTooltip]);

  const handleSchedule = () => {
    if (
      Number.isNaN(webinarSchedule.getUTCHours()) &&
      Number.isNaN(webinarSchedule.getUTCMinutes())
    ) {
      return schedule;
    }
    if (todayUtc < webinarScheduleUtc) {
      return `Schedule ${schedule}`;
    }

    const parseSchedule = new Date(schedule);
    return `${parseSchedule.getDate()}/${parseSchedule.getMonth()}/${parseSchedule.getFullYear()}`;
  };

  const handleButton = () => {
    if (
      todayUtc < webinarScheduleUtc ||
      (Number.isNaN(webinarSchedule.getUTCHours()) && Number.isNaN(webinarSchedule.getUTCMinutes()))
    ) {
      return <Button href={webinarLink}>Sign me up!</Button>;
    }

    return <VideoButton onClick={() => setRenderVideoModal(true)}>Watch recording</VideoButton>;
  };

  return (
    <GlobalWrapper>
      {renderVideoModal && (
        <ModalContainer>
          <VideoContainer ref={modalRef}>
            <VideoTitleContainer>
              <Markdown data={{ content: title }} />
              <CloseIcon onClick={() => setRenderVideoModal(false)} src={CloseIconVideo} />
            </VideoTitleContainer>
            <ReactPlayer controls url={webinarLink} width="100%" height="100%" />
          </VideoContainer>
        </ModalContainer>
      )}
      <TitleContainer>
        <Markdown data={{ content: title }} />
        <WebinarInfo>
          <WebinarInfoWrapper>
            <WebinarInfoIcon src={ScheduleIcon} />
            <Schedule>{handleSchedule()}</Schedule>
            <SeparateLine>|</SeparateLine>
            <WebinarInfoIcon src={DurationIcon} />
            <Duration>Duration {duration} </Duration>
          </WebinarInfoWrapper>
          <WebinarInfoMobileWrapper>
            <MobileContainer>
              <WebinarInfoIcon src={ScheduleIcon} />
              <Schedule>{handleSchedule()}</Schedule>
            </MobileContainer>
            <MobileContainer>
              <WebinarInfoIcon src={DurationIcon} />
              <Duration>Duration {duration} </Duration>
            </MobileContainer>
            <ButtonMobileContainer>{handleButton()}</ButtonMobileContainer>
          </WebinarInfoMobileWrapper>
          <WebinarButtonsWrapper>
            <Share onClick={handleCopyToClipboard}>
              <ShareIcon />
              <ShareTooltip renderTooltip={renderTooltip}>Copied Link!</ShareTooltip>
            </Share>
            <ButtonContainer>{handleButton()}</ButtonContainer>
          </WebinarButtonsWrapper>
        </WebinarInfo>
      </TitleContainer>
      {(careers.data.length > 0 || topics.data.length > 0 || roles.data.length > 0) && (
        <TagsContainer>
          {topics.data.map((topic) => (
            <Tag href={`/webinar?topic=${topic.attributes.Topic}`}>{topic.attributes.Topic}</Tag>
          ))}
          {roles.data.map((role) => (
            <Tag href={`/webinar?role=${role.attributes.Role}`}>{role.attributes.Role}</Tag>
          ))}
          {careers.data.map((career) => (
            <Tag href={`/webinar?career=${career.attributes.Career_Cluster}`}>
              {career.attributes.Career_Cluster}
            </Tag>
          ))}
        </TagsContainer>
      )}
      <SubtitleContainer>
        <Markdown data={{ content: subtitle }} />
      </SubtitleContainer>
    </GlobalWrapper>
  );
};

export default WebinarTitle;
